// CO
import AlleyFreshner from '../images/Colorado/img1.jpg';
// import BlueBear from '../images/Colorado/img2.jpg';
import BlueBear2 from '../images/Colorado/img3.jpg';
import DownTownDenver from '../images/Colorado/img4.jpg';
import DownTownDenver2 from '../images/Colorado/img5.jpg';
import DownTownDenver3 from '../images/Colorado/img6.jpg';
import DownTownDenver4 from '../images/Colorado/img7.jpg';
import DownTownDenver5 from '../images/Colorado/img8.jpg';
import DownTownDenver6 from '../images/Colorado/img9.jpg';
import DownTownDenver7 from '../images/Colorado/img10.jpg';
import DownTownDenver8 from '../images/Colorado/img11.jpg';
// import DownTownDenver9 from '../images/Colorado/img12.jpg';
// import DownTownDenver10 from '../images/Colorado/img13.jpg';
// import DownTownDenver11 from '../images/Colorado/img14.jpg';
// import DownTownDenver12 from '../images/Colorado/img15.jpg';
// import DownTownDenver13 from '../images/Colorado/img16.jpg';
import DownTownDenver14 from '../images/Colorado/img17.jpg';
import DownTownDenver15 from '../images/Colorado/img18.jpg';
import DownTownDenver16 from '../images/Colorado/img19.jpg';
import DownTownDenver17 from '../images/Colorado/img20.jpg';
// AZ
import TempeLake from '../images/Arizona/img18.jpg';
import TempeLake2 from '../images/Arizona/img19.jpg';
import Sedona from '../images/Arizona/img20.jpg';
import Tempe from '../images/Arizona/img21.jpg';
import Sedona2 from '../images/Arizona/img23.jpg';
import Sedona3 from '../images/Arizona/img24.jpg';
import Sedona4 from '../images/Arizona/img25.jpg';
// OR
import Portland from '../images/Oregon/img29.jpg';
import MultnomahFalls from '../images/Oregon/img30.jpg';
import MultnomahFalls2 from '../images/Oregon/img31.jpg';
import LadyBug from '../images/Oregon/img32.jpg';
// WA
import RainboRoad from '../images/Washington/img32.jpg';
import PikePlace from '../images/Washington/img33.jpg';
import UW from '../images/Washington/img34.jpg';
import Seattle from '../images/Washington/img35.jpg';
import Seattle2 from '../images/Washington/img36.jpg';
import Seattle3 from '../images/Washington/img38.jpg';
import Seattle4 from '../images/Washington/img39.jpg';
import Seattle5 from '../images/Washington/img41.jpg';
import GreatWheel from '../images/Washington/img37.jpg';
import KerryPark from '../images/Washington/img40.jpg';
import SpaceNeedle from '../images/Washington/img42.jpg';
import WaterfrontPark from '../images/Washington/img43.jpg';
// TORONTO, CANADA
import RipleysAquarium from '../images/Toronto/img26.jpg';
import CasaLoma from '../images/Toronto/img27.jpg';

const IMAGES = [
  // Colorado
  {
    src: AlleyFreshner,
    thumbnail: AlleyFreshner,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Denver, CO - Alley Freshner',
  },
  // {
  //   src: BlueBear,
  //   thumbnail: BlueBear,
  //   thumbnailWidth: 320,
  //   thumbnailHeight: 212,
  //   caption: 'Denver, CO - Blue Bear',
  // },
  {
    src: BlueBear2,
    thumbnail: BlueBear2,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Denver, CO - Blue Bear',
  },
  {
    src: DownTownDenver,
    thumbnail: DownTownDenver,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Denver, CO - Downtown Denver',
  },
  {
    src: DownTownDenver2,
    thumbnail: DownTownDenver2,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Denver, CO - Downtown Denver',
  },
  {
    src: DownTownDenver3,
    thumbnail: DownTownDenver3,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Denver, CO - Downtown Denver',
  },
  {
    src: DownTownDenver4,
    thumbnail: DownTownDenver4,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Denver, CO - Downtown Denver',
  },
  {
    src: DownTownDenver5,
    thumbnail: DownTownDenver5,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Denver, CO - Dairy Block',
  },

  {
    src: DownTownDenver6,
    thumbnail: DownTownDenver6,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Denver, CO - Millennium Bridge',
  },
  {
    src: DownTownDenver7,
    thumbnail: DownTownDenver7,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Denver, CO - Dairy Block',
  },
  {
    src: DownTownDenver8,
    thumbnail: DownTownDenver8,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Denver, CO - Union Station',
  },
  // {
  //   src: DownTownDenver9,
  //   thumbnail: DownTownDenver9,
  //   thumbnailWidth: 320,
  //   thumbnailHeight: 212,
  //   caption: 'Denver, CO - Union Station',
  // },
  // {
  //   src: DownTownDenver10,
  //   thumbnail: DownTownDenver10,
  //   thumbnailWidth: 320,
  //   thumbnailHeight: 174,
  //   caption: 'Denver, CO - Downtown Denver',
  // },
  // {
  //   src: DownTownDenver11,
  //   thumbnail: DownTownDenver11,
  //   thumbnailWidth: 320,
  //   thumbnailHeight: 212,
  //   caption: 'Denver, CO - Downtown Denver',
  // },
  // {
  //   src: DownTownDenver12,
  //   thumbnail: DownTownDenver12,
  //   thumbnailWidth: 320,
  //   thumbnailHeight: 174,
  //   caption: 'Denver, CO - Downtown Denver',
  // },
  // {
  //   src: DownTownDenver13,
  //   thumbnail: DownTownDenver13,
  //   thumbnailWidth: 320,
  //   thumbnailHeight: 212,
  //   caption: 'Denver, CO - Downtown Denver',
  // },
  {
    src: DownTownDenver15,
    thumbnail: DownTownDenver15,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Denver, CO - Downtown Denver',
  },
  {
    src: DownTownDenver14,
    thumbnail: DownTownDenver14,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Denver, CO - Denver Botanic Gardens',
  },
  {
    src: DownTownDenver16,
    thumbnail: DownTownDenver16,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Denver, CO - Denver Botanic Gardens',
  },
  {
    src: DownTownDenver17,
    thumbnail: DownTownDenver17,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Denver, CO - Denver Botanic Gardens',
  },
  // Arizona
  {
    src: TempeLake,
    thumbnail: TempeLake,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Tempe, AZ - Tempe Lake',
  },
  {
    src: TempeLake2,
    thumbnail: TempeLake2,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Tempe, AZ - Tempe Lake',
  },
  {
    src: Tempe,
    thumbnail: Tempe,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Tempe, AZ - Tempe',
  },
  {
    src: Sedona,
    thumbnail: Sedona,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Sedona, AZ - Sinagua Plaza',
  },
  {
    src: Sedona2,
    thumbnail: Sedona2,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Sedona, AZ - Bell Rock',
  },
  {
    src: Sedona3,
    thumbnail: Sedona3,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Sedona, AZ - Bell Rock',
  },
  {
    src: Sedona4,
    thumbnail: Sedona4,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Sedona, AZ - Bell Rock',
  },
  // Oregon
  {
    src: Portland,
    thumbnail: Portland,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Portland, OR - Portland',
  },
  {
    src: MultnomahFalls,
    thumbnail: MultnomahFalls,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Portland, OR - Multnomah Falls',
  },
  {
    src: MultnomahFalls2,
    thumbnail: MultnomahFalls2,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Portland, OR - Multnomah Falls',
  },
  {
    src: LadyBug,
    thumbnail: LadyBug,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Portland, OR',
  },
  // Washington
  {
    src: RainboRoad,
    thumbnail: RainboRoad,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Seattle, WA - Rainbow Road',
  },
  {
    src: PikePlace,
    thumbnail: PikePlace,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Seattle, WA - Pike Place',
  },
  {
    src: UW,
    thumbnail: UW,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Seattle, WA - University of Washington',
  },
  {
    src: Seattle,
    thumbnail: Seattle,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Seattle, WA - Downtown of Seattle',
  },
  {
    src: Seattle2,
    thumbnail: Seattle2,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Seattle, WA - Seattle Japanese Garden',
  },
  {
    src: GreatWheel,
    thumbnail: GreatWheel,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Seattle, WA - Seattle Great Wheel',
  },
  {
    src: Seattle3,
    thumbnail: Seattle3,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Seattle, WA - Seattle Japanese Garden',
  },
  {
    src: Seattle4,
    thumbnail: Seattle4,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Seattle, WA - Seattle Japanese Garden',
  },
  {
    src: KerryPark,
    thumbnail: KerryPark,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Seattle, WA - Kerry Park',
  },
  {
    src: Seattle5,
    thumbnail: Seattle5,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Seattle, WA - Seattle Japanese Garden',
  },
  {
    src: SpaceNeedle,
    thumbnail: SpaceNeedle,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Seattle, WA - Space Needle',
  },
  {
    src: WaterfrontPark,
    thumbnail: WaterfrontPark,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Seattle, WA - Waterfront Park',
  },
  // Toroto
  {
    src: RipleysAquarium,
    thumbnail: RipleysAquarium,
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: 'Toronto, Canada - Ripley\'s Aquarium of Canada',
  },
  {
    src: CasaLoma,
    thumbnail: CasaLoma,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: 'Toronto, Canada - Casa Loma',
  },
];

export default IMAGES;
