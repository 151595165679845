import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Gallery from 'react-grid-gallery';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { Layout } from '@components';
import { usePrefersReducedMotion } from '@hooks';
import IMAGES from '../images';

const PhotographyPage = ({ location }) => {
  const revealTitle = useRef(null);
  const revealTable = useRef(null);
  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealTable.current, srConfig(200, 0));
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 10)));
  }, []);

  return (
    <Layout location={location}>
      <Helmet title="Photography" />

      <main style={{ display: 'flex', flexDirection: 'column' }}>
        <header ref={revealTitle} style={{ marginBottom: 100 }}>
          <h1 className="big-heading">Photography</h1>
          <p className="subtitle">A display of all my photography</p>
        </header>
        <div>
          <Gallery
            images={IMAGES}
            enableLightbox={true}
            // maxRows={3}
            backdropClosesModal
            // currentImage={3}
            // isOpen={ true}
          />
        </div>
      </main>
    </Layout>
  );
};
PhotographyPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default PhotographyPage;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/projects/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            tech
            github
            external
            company
          }
          html
        }
      }
    }
  }
`;
